import React from "react"
import "bootstrap/dist/css/bootstrap.css"
import SubscriptionComponent from "../components/subscription-component"


const SubscriptionPage = () => (

  <div>
  <SubscriptionComponent></SubscriptionComponent>
  </div>
)

export default SubscriptionPage
